export enum Team {
  OrderOptimization = 'order_optimization',
  PatientInsights = 'patient_insights',
  ProductInfra = 'product_infra',
  IntegrationInfra = 'integration_infra',
  VimOs = 'vim_os',
  Onboarding = 'onboarding',
  Connectivity = 'connectivity_infra',
  OMT = 'omt',
  CDE = 'cde',
  Interfaces = 'interfaces',
}
