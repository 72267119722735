import React from 'react';
import { BrandedH1, Text } from '../../atoms';
import { WeightEnum, SizeEnum, ColorNameEnum } from '../../types';
import './index.less';
import VimLogoSm from '../../../assets/images/logos/vimLogoSm.png';
import SyncData from '../../../assets/images/syncData.svg';

const heading = 'Syncing data, please wait.';
const message = 'Please refrain from moving mouse or keyboard';

export interface SyncDataLoadingProps {
  showLogo: boolean;
}

const SyncDataLoading = ({ showLogo }: SyncDataLoadingProps) => (
  <div className="sync-data-loading">
    {showLogo ? (
      <div className="sync-data-loading-vim-logo">
        <img src={VimLogoSm} alt="" />
      </div>
    ) : null}
    <div className="sync-data-loading-content">
      <img className="sync-svg" src={SyncData} alt="sync-svg" />
      <div>
        <BrandedH1 text={heading} margin="none" />
      </div>
      <div className="sync-data-loading-content-message">
        <Text
          text={message}
          colorName={ColorNameEnum.extraDark}
          weight={WeightEnum.semibold}
          size={SizeEnum['12px']}
        />
        <div className="sync-data-loading-content-message-icons">
          <img
            className="sync-data-loading-content-message-icons-icon"
            alt="keyboard-restrict"
            src="https://static.getvim.com/img/KeyboardOff.svg"
          />
          <img
            className="sync-data-loading-content-message-icons-icon"
            alt="mouse-restrict"
            src="https://static.getvim.com/img/MouseOff.svg"
          />
        </div>
      </div>
    </div>
  </div>
);

export default SyncDataLoading;
