import React, { CSSProperties, FunctionComponent } from 'react';
import classNames from 'classnames';

const h1Size = {
  small: 'text-18',
  default: 'title-24',
  large: 'title-36',
};

const h1Margin = {
  default: 'margin-30',
  medium: 'md-margin',
  small: 'margin-10',
  none: 'no-margin',
};

const h1Align = {
  default: 'text-center',
  left: 'text-left',
  right: 'text-right',
};

type BrandedHeadingProps = {
  id?: string;
  text: string;
  size?: keyof typeof h1Size;
  margin?: keyof typeof h1Margin;
  align?: keyof typeof h1Align;
  className?: string;
};

const BrandedH1: FunctionComponent<BrandedHeadingProps> = ({
  id,
  text,
  size,
  margin,
  align,
  className,
  children,
}) => {
  const actualSize = size || 'default';
  const actualMargin = margin || 'default';
  const actualAlign = align || 'default';
  const fontWeight = 'var(--font-weight)' as CSSProperties['fontWeight'];
  const fontFamily = 'var(--font)';
  const color = 'var(--main-color)';
  return (
    <h1
      className={classNames(
        'title-brand-1',
        h1Size[actualSize],
        h1Margin[actualMargin],
        h1Align[actualAlign],
        className,
      )}
      style={{ color, fontFamily, fontWeight }}
      id={id}
    >
      {children}
      {text}
    </h1>
  );
};

export default BrandedH1;
