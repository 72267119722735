import React, { FunctionComponent, ReactNode } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';

import {
  SizeEnum,
  AlignEnum,
  ColorNameEnum,
  WeightEnum,
  TextTransformEnum,
} from '../../types/enums';

const TextProps = {
  align: PropTypes.oneOf(Object.values(AlignEnum)),
  className: PropTypes.string,
  colorName: PropTypes.oneOf(Object.values(ColorNameEnum)),
  ellipsis: PropTypes.bool,
  textTransform: PropTypes.oneOf(Object.values(TextTransformEnum)),
  html: PropTypes.bool,
  inline: PropTypes.bool,
  underline: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.any,
  size: PropTypes.oneOf(Object.values(SizeEnum)),
  weight: PropTypes.oneOf(Object.values(WeightEnum)),
};

export type TextPropsType = Omit<InferProps<typeof TextProps>, 'text'> & {
  text?: string | ReactNode;
  shouldBreakLines?: boolean;
};

const Text: FunctionComponent<TextPropsType> = ({
  text,
  children,
  size,
  colorName,
  weight,
  align,
  inline,
  underline,
  className,
  textTransform,
  ellipsis,
  html,
  shouldBreakLines,
  ...other
}) => {
  const textInline = inline ? 'inline-block' : undefined;
  const textUnderline = underline ? 'text-underline' : undefined;
  const textEllipsis = ellipsis ? 'three-dots' : undefined;
  let dynamicStyle = {};
  if (colorName === ColorNameEnum.theme) dynamicStyle = { color: 'var(--main-color)' };
  else if (colorName === ColorNameEnum.themeSecondary)
    dynamicStyle = { color: 'var(--secondary-color)' };

  if (shouldBreakLines) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    dynamicStyle['whiteSpace'] = 'pre-line';
  }

  const innerHTML = html && { dangerouslySetInnerHTML: { __html: text! as any } };
  const divChildren = !html ? [text, children] : undefined;
  return (
    <div
      className={classNames(
        size,
        colorName,
        align,
        weight,
        textEllipsis,
        textInline,
        textUnderline,
        textTransform,
        className,
      )}
      style={dynamicStyle}
      {...innerHTML}
      {...other}
    >
      {divChildren}
    </div>
  );
};

Text.propTypes = TextProps;
Text.defaultProps = {
  size: undefined,
  colorName: undefined,
  align: AlignEnum.center,
  weight: undefined,
  inline: false,
  ellipsis: false,
  className: '',
  text: '',
  html: false,
};

export default Text;
