import React, { FC } from 'react';
import './index.less';
import Loader from './data-core-please-wait.svg';

const OptumDataCoreLoader: FC = () => (
  <div className="data-core-writeback-frame-container">
    <div className="data-core-overlay-loader-modal">
      <object type="image/svg+xml" data={Loader} className="data-core-writeback-loading">
        writeback-loading
      </object>
      <h2 className="data-core-main-title">Syncing data, please wait</h2>
    </div>
  </div>
);

export default OptumDataCoreLoader;
