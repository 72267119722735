import React from 'react';
import { SyncDataLoading } from '@getvim/atomic-ui';
import './index.less';

const DefaultLoader: React.FC = () => (
  <div className="write-back-loading-container">
    <div className="write-back-overlay-loader-modal">
      <SyncDataLoading showLogo />
    </div>
  </div>
);

export default DefaultLoader;
